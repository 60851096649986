import {
  Box,
  Center,
  Flex,
  Link,
  Text,
  Container,
  VStack,
  Button,
  Select,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Header } from 'features-unauthenticated/header';
import { useState } from 'react';
import { BiMailSend } from 'react-icons/bi';

const DECOUVERTE_GENERALISTE = 'generaliste';
const DECOUVERTE_POLVILLE = 'polville';

function Number({ number, checked }) {
  return checked ? (
    <Box
      borderRadius={'50%'}
      width="36px"
      height="36px"
      padding={'8px'}
      border="2px solid"
      borderColor="hublot.bleuFonce"
      textAlign={'center'}
      backgroundColor="hublot.bleuFonce"
      color="white"
    >
      <Text fontSize={'xl'} fontWeitgh="800">
        &#x2713;
      </Text>
    </Box>
  ) : (
    <Box
      borderRadius={'50%'}
      width="36px"
      height="36px"
      padding={'8px'}
      border="2px solid"
      borderColor="hublot.bleuFonce"
      textAlign={'center'}
    >
      <Text fontSize={'xl'} fontWeitgh="800">
        {number}
      </Text>
    </Box>
  );
}

export default function Formulaire() {
  const [decouverteChoisi, setDecouverte] = useState(undefined);

  return (
    <Flex
      h="100dvh"
      flexDirection={'column'}
      minH={'400px'}
      direction="column"
      align="center"
      maxW={{ xl: '1200px' }}
      m="0 auto"
      overflow={'auto'}
    >
      <Header
        displayAuthAccessCTA={true}
        displayHublot={true}
        isIntern={true}
      ></Header>

      <Container
        flex={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <VStack spacing={10} alignItems={'flex-start'} width={'100%'}>
          <Text as="h1">Créez votre accès Découverte</Text>
          <Flex>
            <Number number={1} checked={decouverteChoisi} />
            <Box px={4}>
              <Text as="h2" fontSize={'20px'}>
                Choisissez votre formule
              </Text>
              <Select
                placeholder="Choisissez votre Découverte"
                onChange={(e) => setDecouverte(e.target.value)}
              >
                <option value={DECOUVERTE_GENERALISTE}>
                  Découverte ABS et généraliste
                </option>
                <option value={DECOUVERTE_POLVILLE}>
                  Découverte Politique de la Ville
                </option>
              </Select>
            </Box>
          </Flex>
          <Flex>
            <Number number={2} checked={false} />
            <Box px={4} fontSize={'16px'}>
              <Text as="h2" fontSize={'20px'}>
                Créez votre compte
              </Text>
              <VStack spacing={2} alignItems={'flex-start'}>
                <Text>Indiquez vos coordonnées et votre territoire.</Text>
                <Text>
                  Vous recevrez un courrier contenant un lien : cliquez sur ce
                  lien et ainsi validez votre inscription !
                </Text>
                <Text>
                  Notre équipe préparera votre accès et vous enverra par
                  courrier vos identifiants.
                </Text>
              </VStack>
              {decouverteChoisi && (
                <Button
                  as={ReactRouterLink}
                  to={
                    decouverteChoisi === DECOUVERTE_POLVILLE
                      ? 'polville'
                      : 'cabestan'
                  }
                  my={4}
                  p="4"
                  border="1px solid"
                  borderColor="hublot.bleuElectrique"
                  isDisabled={!decouverteChoisi}
                  isActive={decouverteChoisi}
                >
                  Je crée mon compte
                </Button>
              )}
            </Box>
          </Flex>
          <Center>
            <BiMailSend size={25} />
            <VStack p={'2'}>
              <Box>
                <Text fontWeight={'bold'}>Une question ?</Text>
                <Text fontWeight={'bold'}>Pour nous contacter</Text>
                <Link href="mailto:contact@compas-tis.com">
                  contact@compas-tis.com
                </Link>
              </Box>
            </VStack>
          </Center>
        </VStack>
      </Container>
    </Flex>
  );
}
